<template>
  <div class="content">
    <!-- 填选 -->
    <div class="fillBox">
      <!-- 选择 -->
      <div style="border-bottom: 1px solid #eeeeee">
        <van-cell
          is-link
          required
          title="帮扶党员"
          :value="chooseValue"
          @click="show = true"
        />
        <!-- 弹框 -->
        <van-popup v-model="show" position="bottom">
          <van-picker
            title="党员"
            show-toolbar
            :columns="pairList"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </van-popup>
      </div>
      <!-- 详情记录 -->
      <van-field
        label="详情记录"
        required
        v-model="message"
        rows="6"
        autosize
        type="textarea"
        placeholder="请输入详情记录"
      />
    </div>
    <!-- 空隙 -->
    <div class="gap"></div>
    <!-- 上传图片 -->
    <div class="imgBox">
      <div style="font-size: 12px; color: #666; margin-bottom: 8px">
        <span style="font-size: 16px; color: #333; font-weight: 500"
          >上传图片 </span
        >（最多3张）
      </div>
      <van-uploader
        v-model="fileList"
        :max-count="3"
        multiple
        :before-read="beforeRead"
        :after-read="afterRead"
        result-type="dataUrl"
      />
      <div style="font-size: 12px; color: #666; margin-top: 10px">
        注：1{{ config.supportIntervalStr }}帮扶{{
          config.maxSupportCount
        }}次，提交帮扶日志，最高获得{{ config.maxSupportIntegral }}积分
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="btnBox" @click="submit">提交</div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import {
  requestPairList,
  requestConfig,
  requestCheck,
  requestLogSave,
  uploadFile,
} from '../../api/pairSupport';
export default {
  data() {
    return {
      show: false,
      chooseValue: '请选择帮扶党员',
      chooseId: '',
      pairList: [],
      message: '',
      fileList: [],
      config: {},
    };
  },
  created() {
    this.getPairList();
    this.getConfig();
  },
  methods: {
    // 确定
    onConfirm(value, index) {
      console.log(value, index);
      this.chooseValue = value.text;
      this.chooseId = value.defaultIndex;
      this.show = false;
    },
    // 取消
    onCancel(value, index) {
      this.show = false;
    },
    // 获取已配对老党员列表
    getPairList() {
      requestPairList({
        current: 1,
        size: 9999,
        supportId: '',
        realName: '',
      }).then((res) => {
        if (res.success) {
          res.data.records.forEach((item) => {
            this.pairList.push({
              defaultIndex: item.id,
              text: item.realName,
            });
          });
          console.log('获取已配对的帮扶对象: ', this.pairList);
        }
      });
    },
    // 获取周期配置
    getConfig() {
      requestConfig({}).then((res) => {
        if (res.success) {
          this.config = res.data;
        }
      });
    },
    // 保存日志
    saveLog() {
      // 必填提示
      if (this.chooseValue == '请选择帮扶党员') {
        Notify({ type: 'warning', message: '请选择帮扶党员' });
        return false;
      }
      if (!this.message) {
        Notify({ type: 'warning', message: '请填写详情记录' });
        return false;
      }
      // 校验
      requestCheck({ supportedId: this.chooseId }).then((res) => {
        if (res.data) {
          const fileList = this.fileList.map((item) => item.url);
          requestLogSave({
            supportedId: this.chooseId,
            content: this.message,
            images: fileList.join(','),
          }).then((res) => {
            this.$router.go(-1);
          });
        }else{
          Notify({ type: 'danger', message: '您的帮扶次数已经到达最高' });
        }
      });
    },
    // 提交
    submit() {
      this.saveLog();
    },
    // 上传前校验
    beforeRead(file) {
      console.log(this.fileList);
      if (Array.isArray(file)) {
        let notType = false;
        file.forEach((item) => {
          if (
            item.type !== 'image/jpeg' &&
            item.type !== 'image/png' &&
            item.type !== 'image/gif'
          ) {
            notType = true;
          }
        });
        if (notType) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
        if (file.length > 3 - this.fileList.length) {
          Notify({
            type: 'warning',
            message: '您上传的张数超过上限，请重新选择',
          });
          return false;
        }
      } else {
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif'
        ) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
      }

      return true;
    },
    // 上传服务器
    afterRead(file) {
      const this_ = this;
      if (Array.isArray(file)) {
        file.forEach((fileItem) => {
          // console.log(fileItem, this.fileList);
          fileItem.status = 'uploading';
          fileItem.message = '上传中...';
          const formdata = new FormData();
          formdata.append('file', file.file);
          lrz(fileItem.file, {})
            .then(async function (rst) {
              //成功时执行
              console.log('压缩成功');
              const formdata = rst.formData;
              await uploadFile(formdata)
                .then((res) => {
                  res = res.data;
                  if (res.success) {
                    fileItem.status = 'done';
                    fileItem.url = res.data.link;
                    Notify({ type: 'success', message: '上传成功' });
                  } else {
                    fileItem.status = 'failed';
                    fileItem.message = '上传失败';
                    Notify({ type: 'warning', message: '上传失败' });
                  }
                })
                .catch((err) => {
                  fileItem.status = 'failed';
                  fileItem.message = '上传失败';
                  Notify({ type: 'warning', message: '上传失败' });
                  console.log(err);
                });
            })
            .catch(function (error) {
              Notify({ type: 'warning', message: '图片压缩失败' });
              //失败时执行
            })
            .always(function () {
              //不管成功或失败，都会执行
            });
        });
      } else {
        console.log(file, this.fileList);
        file.status = 'uploading';
        file.message = '上传中...';
        // const formdata = new FormData();
        // formdata.append('file', file.file);
        lrz(file.file, {})
          .then(function (rst) {
            //成功时执行
            console.log('压缩成功');
            const formdata = rst.formData;
            uploadFile(formdata)
              .then((res) => {
                res = res.data;
                if (res.success) {
                  file.status = 'done';
                  file.url = res.data.link;
                  Notify({ type: 'success', message: '上传成功' });
                } else {
                  this_.fileList = this_.fileList.slice(
                    0,
                    this_.fileList.length - 1
                  );
                  Notify({ type: 'warning', message: '上传失败' });
                }
              })
              .catch((err) => {
                this_.fileList = this_.fileList.slice(
                  0,
                  this_.fileList.length - 1
                );
                Notify({ type: 'warning', message: '上传失败' });
                console.log(err);
              });
          })
          .catch(function (error) {
            console.log(error);
            Notify({ type: 'warning', message: '图片压缩失败' });
            //失败时执行
          })
          .always(function () {
            //不管成功或失败，都会执行
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content {
  width: 100%;
  height: 100%;
  .fillBox {
    padding: 32 * $px;
  }
  .gap {
    width: 100%;
    height: 24 * $px;
    background: #f5f5f5;
  }
  .imgBox {
    padding: 32 * $px;
  }
  .btnBox {
    width: 642 * $px;
    height: 96 * $px;
    background: #1677ff;
    position: fixed;
    bottom: 50 * $px;
    left: 54 * $px;
    font-size: 36 * $px;
    color: #fff;
    text-align: center;
    line-height: 96 * $px;
  }
}
</style>
